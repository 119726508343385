import React, { useState, useEffect } from "react";
import { useMedia } from "use-media";

import Layout from "../../../components/chat-channel/Layout";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Container from "../../../components/chat-channel/Container";
import TitleAndMetaTags from "../../../components/common/TitleAndHeader";
import { RequestForm } from "../../../components/form";
import { OnScrollPopup } from "../hr-chatbot";
import { GoToPopup } from "../../homepage";
import { CookiesPoup } from "../../homepage";
import {
  cta_1,
  cta_1_mob,
  cta_2,
  cta_2_mob,
  ExistingBlogCta,
} from "./4-reasons-to-use-ms-teams-as-a-digital-helpdesk";
import { redirectToWithUtm } from "../../../components/Header";
import "react-accessible-accordion/dist/fancy-example.css";

const topImage = require("../../../assets/images/market_ui/goto_testimonial_cta.png");

const BlogHeader = require("../../../assets/img/blogs/header_blog_onboarding.png.webp");

const ldSchema = {
  "@context": "https://schema.org",
  "@type": "BlogPosting",
  mainEntityOfPage: {
    "@type": "WebPage",
    "@id":
      "https://workativ.com/conversational-ai-platform/slack-chatbot-guide",
  },
  headline: "Slack Chatbot Guide for your Business",
  image: "",
  author: {
    "@type": "Organization",
    name: "Team Workativ",
  },
  publisher: {
    "@type": "Organization",
    name: "Workativ",
    logo: {
      "@type": "ImageObject",
      url:
        "https://workativ.com/static/workativ-logo-5eea21f2dcd20bbbbf52debb41c0f26e.png",
    },
  },
  datePublished: "2021-08-05",
  dateModified: "2021-08-05",
};

export default function FeatureHomePage() {
  const isSmall = useMedia({ maxWidth: "520px" });

  return (
    <>
      <TitleAndMetaTags
        title="Onboarding Challenges IT Service Desk Face with New Agents"
        description="We discuss how AI and ML are expected to revolutionize IT support and why you must try it for which you must understand their functions."
        keywords={[
          "Slack Chatbot, Automation, AI Chatbot",
          "workativ free trial",
          "Slack chatbot free trial",
          "Slack Chatbot",
        ]}
        ogImage={BlogHeader}
        schema={ldSchema}
      />
      <section className="market_main_wrapper">
        <Container>
          <Layout backgroundColor={"_bg_market"} logoFor="ASSISTANT">
            <div className="grid_container_chatbot">
              <div className="container">
                <div className="row">
                  <div className="grid_container_chatbot_wrapper">
                    <div className="grid__wrapper">
                      <div className="col-lg-12 col-md-12 col-xs-12 trial_header_left">
                        <div className="main-slider-left-market">
                          <h1 className="font-page-header ">
                            Onboarding Challenges IT Service Desk Face with New
                            Agents
                          </h1>
                        </div>
                      </div>
                      <img src={BlogHeader} />
                      <div className=" mt-5 market_wrapper_page">
                        <p class="font-section-normal-text line-height-2">
                          The IT Service Desk is a primary point of engagement
                          between users and an IT organization. To put it in
                          simple terms, it is the single point of contact
                          between the service providers and users for day-to-day
                          activities.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          Typically, a service desk manages service disruptions
                          and service requests besides handling user
                          communications or outages and planned changes to
                          services.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          With the expansion of digitization, the demand for
                          help desk services is increasing at a rapid pace as
                          well as the need for skilled IT agents
                        </p>

                        <p class="font-section-normal-text line-height-2">
                          In a recent survey by{" "}
                          <a href="https://www.kornferry.com/">Korn Ferry</a>,
                          90% of executives said that recruiting experienced IT
                          agents and retaining them is the biggest challenge of
                          the IT service desks these days.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          In this blog post, we shall discuss the significant
                          challenges IT service desk encounter while onboarding
                          a new agent, and how{" "}
                          <a href="https://workativ.com/conversational-ai-platform/how-ai-powered-chatbots-with-automated-workflows-can-help-digital-workers">
                            automation can make a difference.{" "}
                          </a>
                        </p>
                      </div>

                      <NocodeWrapper />

                      <div>
                        <h2 className="font-section-sub-header-small-bold">
                          Inability to adjust due to lack of soft skills
                        </h2>
                        <p class="font-section-normal-text line-height-2">
                          There are specific skills that an IT service desk
                          agent must-have. Alongside technical skills (part of
                          the IT service desk training), the service agent must
                          stay calm under pressure, be patient, and must have an
                          eagerness to develop new technical skills. Often, it
                          becomes difficult for IT service desks to find an
                          agent having the perfect combination of the requisite
                          technical knowledge and these soft skills.
                        </p>
                        <h6 className="font-section-sub-header-small-bold">
                          Uncoordinated training
                        </h6>
                        <p class="font-section-normal-text line-height-2">
                          Further to recruiting new employees, it becomes
                          necessary for the management to step forward and take
                          the steps required to train them. They should be
                          updated about new services, products or on any new
                          policy change, etc. The training should be coordinated
                          along with other work activities as this would help
                          service agents answer and address the issues of the
                          customers seamlessly.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          Currently, the biggest problem that the industry is
                          facing is a lack of relevant content (such as
                          knowledge base videos or blogs that will help them
                          understand the process, job deliverables, and
                          expectations of the company) to train the recruits.{" "}
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          As a result, it leads to a failure to identify their
                          role in the team, which could affect productivity.
                        </p>
                        <h6 className="font-section-sub-header-small-bold">
                          Long Resolution Times
                        </h6>
                        <p class="font-section-normal-text line-height-2">
                          The more businesses become technology-oriented, the
                          higher is the need for troubleshooting, which becomes
                          immensely tricky if the service desk receives too many
                          requests. Post recruiting new agents, it has been
                          found that the time taken by such employees to solve
                          one ticket considerably exceeds the industry standard,
                          which again leads to mitigated productivity.
                        </p>

                        <h6 className="font-section-sub-header-small-bold">
                          No Sync in the Team
                        </h6>
                        <p class="font-section-normal-text line-height-2">
                          After recruiting new employees, the existing team
                          often find it challenging to understand the skills the
                          recruits possess and the part of the projects they
                          will handle, which leads to lower productivity.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          An interesting case study reveals that with recruits,
                          it becomes quite challenging to track individual
                          productivity daily and the amount of time consumed on
                          solving each ticket.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          The study added that poor sync between the existing
                          team and new joiners is the root cause behind this
                          issue. Owing to this reason, managers often face
                          difficulty in keeping track of the amount of work done
                          and the overall progress of the projects.
                        </p>

                        <h6 className="font-section-sub-header-small-bold">
                          Inability to efficiently operate new tools
                        </h6>
                        <p class="font-section-normal-text line-height-2">
                          Though there are tools that offer a variety of
                          self-service features, recruits often face issues
                          while working with them. It can be either due to a
                          shortage of skill or inability to operate the tool
                          used specifically by that IT service desk.
                        </p>

                        <p class="font-section-normal-text line-height-2">
                          <a href="https://workativ.com/conversational-ai-platform/blog/transform-service-desk-with-it-chatbot">
                            IT service desks are heavily dependent on tools,
                          </a>{" "}
                          and the continuous advancement of technology has its
                          own set of challenges. Introducing too many in a short
                          period leads to inconsistent results and costly
                          mistakes.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          Using a tool having the ability to pull through
                          information from a wide variety of sources, and most
                          importantly, training the team how to operate the same
                          can help avoid such issues.{" "}
                        </p>
                        <h6 className="font-section-sub-header-small-bold">
                          The short tenure of recruits
                        </h6>
                        <p class="font-section-normal-text line-height-2">
                          Onboarding recruits for{" "}
                          <a href="https://workativ.com/conversational-ai-platform/blog/service-desk-chatbot-guide">
                            service desk is a challenge by itself
                          </a>{" "}
                          but also one of the reasons that{" "}
                          <a href="https://workativ.com/use-cases/employee-onboarding-automation">
                            onboarding has become very important{" "}
                          </a>
                          in recent times is because the tenure of agents is
                          rather short. Hence, the team has to continuously
                          recruit new candidates and onboard them to help
                          individuals fit in with the team, adapt to the team
                          culture, and stay longer in the company.
                        </p>

                        <p class="font-section-normal-text line-height-2">
                          A study revealed that the average tenure for an IT
                          agent is about 11 months. The same study showed that
                          this high turnover is leading to higher operating
                          costs as they need to continue to train new staff.
                        </p>

                        <h6 className="font-section-sub-header-small-bold">
                          How Can Automation Change the Current Scenario?
                        </h6>
                        <p class="font-section-normal-text line-height-2">
                          According to a survey, improper recruitment and lack
                          of specific talents are the main reason behind the
                          demand-supply gap of human resources in the IT service
                          industry.
                        </p>

                        <p class="font-section-normal-text line-height-2">
                          As a monthly task, it becomes tedious for the HR team
                          and managers to recruit new, skilled employees.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          Studies conducted by eminent market research firms
                          have found out that{" "}
                          <a href="https://workativ.com/conversational-ai-platform/call-deflection">
                            bringing the ticket ratio down,
                          </a>{" "}
                          or helping them solve the tickets quickly, can help
                          improve employee retention. The same study stated that
                          automation is a great way to achieve this purpose.
                        </p>

                        <h6 className="font-section-sub-header-small-bold">
                          A new addition to automation is AI.
                        </h6>
                        <p class="font-section-normal-text line-height-2">
                          <a href="https://workativ.com/conversational-ai-platform/it-helpdesk-chatbot">
                            {" "}
                            AI-powered chatbots{" "}
                          </a>
                          can help users find their answers on their own. Some
                          chatbots are so efficient that users don’t even
                          understand they are talking to an AI-bot, as they
                          unknowingly solve their issues.
                        </p>

                        <p class="font-section-normal-text line-height-2">
                          In other words, AI-based tools reduce pressure on the
                          existing team and improve retention rates. This way,
                          there is no need for companies to seek new employees
                          every month. The net result? A drastic fall in the
                          operating cost.
                        </p>
                      </div>
                      <ExistingBlogCta
                        ContentCta="Auto-resolve 60% of Your Employee Queries With Generative AI Chatbot & Automation."
                        ButtonText="Book a Demo"
                        isColor="black"
                        backgroundImage={cta_1}
                        mobileBackgroundImage={cta_1_mob}
                      />
                      <div className="most_popular_links">
                        <div className="most_popular_header font-section-sub-header-bold">
                          Related Articles
                        </div>
                        <div className="most_popular_ul">
                          <ul
                            className="section__ul"
                            style={{ width: "100%", float: "left" }}
                          >
                            <li>
                              <a href="https://workativ.com/conversational-ai-platform/blog/llm-security-service-desk">
                                Security Best Practices to Use LLMs for Service
                                Desk Automation
                              </a>
                            </li>
                            <li>
                              <a href="https://workativ.com/conversational-ai-platform/conversational-ai-chatbot">
                                {" "}
                                What Is Conversational AI? A Guide to
                                Conversational AI Chatbots
                              </a>
                            </li>
                            <li>
                              <a href="https://workativ.com/conversational-ai-platform/blog/generative-ai-enterprise">
                                How can Enterprises Leverage Generative AI?
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div className="button_last">
                        <button>
                          <a href="https://blog.workativ.com/category/conversational-ai">
                            Conversational AI
                          </a>
                        </button>
                      </div>
                    </div>
                    <div className="sticky_right_img">
                      <a href="/conversational-ai-platform/case-studies/goto-implements-chatbot-for-it-helpdesk-automation">
                        {" "}
                        <img src={topImage} alt="goto testimonial cta" />{" "}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* {isSmall ? null : <OnScrollPopup />} */}

            {/* <GoToPopup />
            <CookiesPoup /> */}
            <RequestForm isFooterForm={true} />
          </Layout>
        </Container>
      </section>
    </>
  );
}

const NocodeWrapper = () => {
  return (
    <div className="nocode_wrapper mt-0 ">
      <h4>No Code - Free Conversational AI Chatbot </h4>
      <button>
        <a
          href="https://assistant.workativ.com/authentication/u/direct-signup"
          alt="Conversational AI chatbot"
        >
          Get Started
        </a>
      </button>
    </div>
  );
};
